$primary: #c19d60;
$light: rgba(217, 217, 217, 1);
$dark: #1e1e1e;

.contact-info {
    .contact-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 20px;
        color: $light;

        .detail {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            gap: 16px;

            .icon-wrapper{
                height: 100px;
                width: 100px;
                border: 1px solid rgba(217, 217, 217, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                background: linear-gradient(to bottom right, rgba(217, 217, 217, 0.1) 0%, rgba(115, 115, 115, 0) 100%);
            }

            .content-wrapper{
                display: flex;
                flex-direction: column;
                gap: 14px;


                h3 {
                    margin: 0;
                    font-size: 24px;
                    color: $primary;
                    font-weight: bold;
                }
    
                p {
                    margin: 0;
                    font-size: 16px;
                    color: $primary;
                }
            }

        }
    }

}