$primary: #c19d60;
$light: #d9d9d9;
$dark: #1E1E1E;

.card-component-daily-special {
    position: relative;
    // width: 100%;
    height: 200px; // Adjust height as needed
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 8px;

    .inner-card {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 16px;
        background: linear-gradient(to bottom left, rgba(48, 48, 48, 0.5) 0%, rgb(30, 30, 30) 100%);


        .top-left-corner {
            position: absolute;
            top: 16px;
            left: 16px;
            width: 80px; // Adjust size as needed
            height: 80px;
            border-top: 2px solid $light;
            border-left: 2px solid $light;
            border-top-left-radius: 8px;
            font-size: 48px;
            font-weight: bold;
            color: $light;
            padding: 0 16px;
        }
        .day{
            font-size: 24px;
            font-weight: bold;
            color: $primary;
        }
        .text{
            font-size: 18px;
            font-weight: bold;
            color: $light;
        }
    }
}
