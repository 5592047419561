$primary: #c19d60;
$light: #d9d9d9;
$dark: #1E1E1E;

.full-width-div {
    width: 100%;
    height: 300px; // Adjust height as needed
    background-image: url('../../Assets/Images/BreadCrumb.png'); // Replace with your image path
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hang-up {
    margin-top: -130px;
    z-index: 1;
}

.centered-text {
    margin-top: 100px;
    font-size: 36px; // Adjust text size as needed
    color: white; // Adjust text color as needed
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); // Optional: add shadow for better readability
    font-weight: bold;
}

.spacer {
    height: 50px;
}

.category-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto; // Enables horizontal scrolling
    white-space: nowrap;
    padding: 8px 0;
    scrollbar-width: thin; // For Firefox
    scrollbar-color: $primary $light; // For Firefox
}

// Custom scrollbar styles for Webkit browsers (Chrome, Safari)
.category-wrapper::-webkit-scrollbar {
    height: 8px;
}

.category-wrapper::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 10px;
    transition: background-color 0.3s ease; // Smooth transition
}

.category-wrapper::-webkit-scrollbar-track {
    background-color: $light;
    border-radius: 10px;
    transition: background-color 0.3s ease; // Smooth transition
}

// Hover effect for Webkit browsers (Chrome, Safari)
.category-wrapper:hover::-webkit-scrollbar-thumb {
    background-color: $dark;
}

.category-wrapper:hover::-webkit-scrollbar-track {
    background-color: $dark;
}

.category-name {
    padding: 8px 16px;
    border: 1px solid rgba(217, 217, 217, 0.1);
    border-radius: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    flex: 0 0 auto; // Prevents the items from shrinking
}

.active-category {
    background-color: $primary;
    color: $dark;
}

