$primary: #c19d60;
$light: #d9d9d9;
$dark: #1E1E1E;

.information-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;

  @media (max-width: 991px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.information-wrapper-2 {
  display: flex;
  flex-direction: row;
}

.text-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: $primary;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
}

.text-icon-container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: $dark;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  justify-content: center;
}

.information-wrapper-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
}

.product-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  img {
    max-width: 100%;
    height: auto;
    max-height: 150px; // Adjust as necessary
  }
}

.nav-items {
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    display: none;
  }

  nav {
    ul {
      list-style: none;
      display: flex;
      gap: 16px;
      padding: 0;
      margin: 0;

      li {
        a {
          text-decoration: none;
          font-size: 16px;
          padding: 8px;
          color: $light;
          transition: color 0.3s ease, border-bottom 0.3s ease;

          &.selected {
            border-bottom: 2px solid $primary;
            color: $primary;
          }

          &:hover {
            color: darken($primary, 10%);
          }
        }
      }
    }
  }
}

.burger-menu {
  display: none;
  cursor: pointer;

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
  }
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .drawer-content {
    background: $dark;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;

    .drawer-close {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    nav {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin: 16px 0;

          a {
            text-decoration: none;
            font-size: 18px;
            color: $primary;
            transition: color 0.3s ease;
            color: $light;

            &.selected {
              border-bottom: 2px solid $primary;
              color: $primary;
            }

            &:hover {
              color: darken($primary, 10%);
            }
          }
        }
      }
    }
  }
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 8px;
  // height: calc(100vh - 200px);
  justify-content: flex-end;
  margin-top: 100px;

  button {
    background-color: $primary;
    width: 200px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary, 10%);
    }
  }
}

.hero-title {
  font-family: 'Gotham', sans-serif;

  span {
    color: $primary;
  }
}

.hero-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 200px);
  padding: 8px;
  margin-top: 40px;

  .hero-image {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
