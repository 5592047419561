

.App {
  background-color: #171717;
  min-height: 100svh;
  margin: 0;
  padding: 0;
  color: #D9D9D9;
  overflow-x: hidden;

}

/* Scroller */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #171717;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}